class MiniCart {

    refresh() {

        let req = sf.ajax({
            dst: '/cart/get-mini-cart-count',
            method: 'GET',
            callback: (res) => {

                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {

                    let miniCartCounts = document.querySelectorAll('.cart-count');
                    for(let miniCartCount of miniCartCounts) {
                        miniCartCount.innerHTML = data.count;
                    }

                    let el = document.querySelector('.cart.headhesive')

                    if (data.count == 0) {
                        el.classList.add('invisible-zero');
                    } else {
                        el.classList.remove('invisible-zero');
                    }

                }

            },
            fallBack: () => {

                sf.alert('Ошибка соединения.', 'err');

            },

        });

        req.send();

        fetch('/cart/popup/content', { credentials: 'include' })
            .then(res => res.json())
            .then(json => {

                sf.alert(json.notify);

                if (json.status == 'ok') {

                    let miniCartPopups = document.querySelectorAll('#hidd-basket');
                    for(let miniCartPopup of miniCartPopups) {
                        miniCartPopup.innerHTML = json.content;
                    }

                }

            });

    }

}

const miniCart = new MiniCart;
document.addEventListener("DOMContentLoaded", miniCart.refresh());
